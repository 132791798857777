<template>
  <div>
    <el-dialog
      title="拼团成员"
      :visible.sync="showDialog"
      width="1200px"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      @close="onCloseDialog"
    >
      <span class="content-box">
        <el-table :data="tableList" size="mini" border stripe>
          <!-- <el-table-column prop="UserId" label="用户ID"> </el-table-column> -->
          <el-table-column prop="" label="微信头像">
            <template slot-scope="scope">
              <el-image :src="scope.row.UserHead" fit="fill"></el-image>
            </template>
          </el-table-column>
          <el-table-column prop="UserName" label="微信昵称"></el-table-column>
          <el-table-column prop="" label="拼团角色">
            <template slot-scope="scope">
                <el-tag :type="scope.row.IsCreator ? 'warning' : 'primary'" effect="dark">{{ scope.row.IsCreator ? '团长' : '团员' }}</el-tag>
            </template>
          </el-table-column>
          <el-table-column prop="OrderNo" label="订单号"></el-table-column>
          <el-table-column prop="" label="订单金额">
            <template slot-scope="scope">¥ {{ scope.row.OrderAmount }}</template>
          </el-table-column>
          <el-table-column prop="ReceiveContact" label="收货人"></el-table-column>
          <el-table-column prop="ReceivePhone" label="联系方式"></el-table-column>
          <el-table-column prop="ReceiveAddress" label="收货地址"></el-table-column>
          <el-table-column prop="" label="下单时间" width="150px">
            <template slot-scope="scope">
              {{
                util.dateFormat(
                  "YYYY-mm-dd HH:MM:SS",
                  new Date(scope.row.OrderTime * 1000)
                )
              }}
            </template>
          </el-table-column>
          <el-table-column prop="" label="操作">
            <template slot-scope="scope">
              <el-button
                type="primary"
                size="small"
                @click="onGotoOrderList(scope.row)"
                >订单详情</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </span>
      <span slot="footer">
        <el-button @click="onCloseDialog">取 消</el-button>
        <el-button type="primary" @click="onCloseDialog">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import Shop from "@/api/shop.js";

export default {
  data() {
    return {
      grouponId: 0,
      tableList: [],
      showDialog: false,
    };
  },

  mounted() {},

  methods: {
    // 获取拼团员工列表
    async getGrouponPartnerList() {
      try {
        let { data } = await Shop.getGrouponPartnerList({
          groupon_id: this.grouponId,
        });
        this.tableList = data;
      } catch (err) {
        console.log(err);
      }
    },

    onGotoOrderList(event){
        this.$router.push({
            path: '/shop/order',
            // name: 'ShopOrder',
            query: {
                order_id: event.OrderId
            }
        })
    },

    // 打开对话框
    onShowDialog(event) {
      this.grouponId = JSON.parse(JSON.stringify(event.GrouponId || 0));
      this.getGrouponPartnerList();
      this.showDialog = true;
    },

    // 关闭对话框
    onCloseDialog() {
      this.showDialog = false;
    },
  },
};
</script>

<style lang="less" scoped>
.content-box {
    max-height: 60vh;

    .el-table{
        .el-image {
            width: 60px;
            height: 60px;
        }
    }
}
</style>
